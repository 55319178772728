import { useState, useEffect } from 'react'
import { createSlice } from '@reduxjs/toolkit'
import { firestore, auth, storage } from 'utils/firebase'
import firebase from 'firebase'
import { addDays } from 'date-fns'

// ------------------------------------
// State
// ------------------------------------

const initialState = {
  checked: false,
  loggedIn: false,
  userType: 'advertiser',
  me: {},
  selectedVehicle: localStorage.getItem('selected_vehicle')
    ? JSON.parse(localStorage.getItem('selected_vehicle'))
    : {
        vehicleId: '',
        vehicleNumber: '',
        totalBalance: 0,
        totalHours: 0,
        regions: [],
        commissionedBy: [],
        preferredTimes: [],
      },
  selectedCampaign: {
    campaignId: '',
    name: '',
    regions: [],
    startDate: '',
    endDate: '',
    perDayBudget: 0,
    totalBudget: 0,
    impressions: 0,
  },
  savedWithdrawalId: localStorage.getItem('saved_withdrawal')
    ? localStorage.getItem('saved_withdrawal')
    : '',
  companiesList: {},
  regionList: {},
  spotPrice: 20,
  timeOptions: [
    { value: '0', label: '00:00 - 01:00', spot_fee: 20 },
    { value: '1', label: '01:00 - 02:00', spot_fee: 20 },
    { value: '2', label: '02:00 - 03:00', spot_fee: 20 },
    { value: '3', label: '03:00 - 04:00', spot_fee: 20 },
    { value: '4', label: '04:00 - 05:00', spot_fee: 20 },
    { value: '5', label: '05:00 - 06:00', spot_fee: 20 },
    { value: '6', label: '06:00 - 07:00', spot_fee: 20 },
    { value: '7', label: '07:00 - 08:00', spot_fee: 20 },
    { value: '8', label: '08:00 - 09:00', spot_fee: 20 },
    { value: '9', label: '09:00 - 10:00', spot_fee: 20 },
    { value: '10', label: '10:00 - 11:00', spot_fee: 20 },
    { value: '11', label: '11:00 - 12:00', spot_fee: 20 },
    { value: '12', label: '12:00 - 13:00', spot_fee: 20 },
    { value: '13', label: '13:00 - 14:00', spot_fee: 20 },
    { value: '14', label: '14:00 - 15:00', spot_fee: 20 },
    { value: '15', label: '15:00 - 16:00', spot_fee: 20 },
    { value: '16', label: '16:00 - 17:00', spot_fee: 20 },
    { value: '17', label: '17:00 - 18:00', spot_fee: 20 },
    { value: '18', label: '18:00 - 19:00', spot_fee: 20 },
    { value: '19', label: '19:00 - 20:00', spot_fee: 20 },
    { value: '20', label: '20:00 - 21:00', spot_fee: 20 },
    { value: '21', label: '21:00 - 22:00', spot_fee: 20 },
    { value: '22', label: '22:00 - 23:00', spot_fee: 20 },
    { value: '23', label: '23:00 - 00:00', spot_fee: 20 },
  ],
}

// ------------------------------------
// Slices
// -----------------------------------

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMe: (state, action) => ({
      ...state,
      me: action.payload.me,
      loggedIn: action.payload.loggedIn,
      userType: action.payload.userType,
      checked: true,
    }),
    setCompanies: (state, action) => ({
      ...state,
      companiesList: action.payload.companiesList,
    }),
    setRegions: (state, action) => ({
      ...state,
      regionList: action.payload.regionList,
    }),
    setLoggedIn: (state, action) => ({
      ...state,
      loggedIn: action.payload,
    }),
    setCampaigns: (state, action) => ({
      ...state,
      campaigns: action.payload.campaigns,
    }),
    setSelectedVehicle: (state, action) => ({
      ...state,
      selectedVehicle: action.payload.selectedVehicle,
    }),
    setSelectedCampaign: (state, action) => ({
      ...state,
      selectedCampaign: action.payload.selectedCampaign,
    }),
    setDraftCampaign: (state, action) => ({
      ...state,
      draftCampaign: action.payload.draftCampaign,
    }),
    setWithdrawalId: (state, action) => ({
      ...state,
      savedWithdrawalId: action.payload.savedWithdrawalId,
    }),
  },
})

// ------------------------------------
// Actions
// -----------------------------------

export const getCompanies = () => async (dispatch) => {
  const companyData = await firestore.collection('companies').get()
  const companyList = []

  companyData.docs.map((doc) => {
    companyList.push({
      value: doc.id,
      label: doc.data().name,
    })
    return doc.id
  })

  console.log('get companies ', companyList)

  // login
  return dispatch(
    slice.actions.setCompanies({
      companiesList: companyList,
    }),
  )
}

export const loadRegions = () => async (dispatch) => {
  const regionData = await firestore.collection('regions').get()
  const regionList = []

  regionData.docs.map((doc) => {
    const _rData = doc.data()

    regionList.push({
      value: doc.id,
      label: _rData.name,
      identifier: _rData.name,
      radius: _rData.radius,
      latitude: _rData.latitude,
      longitude: _rData.longitude,
    })
    return doc.id
  })

  // region list
  return dispatch(
    slice.actions.setRegions({
      regionList,
    }),
  )
}

export const authenticate = () => (dispatch) => {
  auth.onAuthStateChanged(async (me) => {
    if (!me) {
      return dispatch(
        slice.actions.setMe({
          loggedIn: false,
          checked: true,
          me: {},
        }),
      )
    }

    // get user from firestore
    const user = await firestore.collection('users').doc(me?.uid).get()
    const userData = user.data();
    // login
    return dispatch(
      slice.actions.setMe({
        loggedIn: me?.emailVerified && user.exists,
        me: user.exists
          ? { id: me?.uid, emailVerified: me?.emailVerified, ...user.data() }
          : {},
        checked: true,
        userType: userData.usertype
      }),
    )
  })
}

const signup = ({
  fullName,
  company,
  branch,
  tel,
  location,
  email,
  usertype,
  password,
}) => () =>
  new Promise(async (resolve, reject) => {
    try {
      // create user
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password,
      )

      // send confirmation email
      await user.sendEmailVerification()

      // store user info in firestore
      await firestore.collection('users').doc(user.uid).set({
        fullName,
        company,
        branch,
        tel,
        location,
        email,
        usertype,
      })

      resolve(user)
    } catch (err) {
      reject(err)
    }
  })

const updateProfile = ({
  uid,
  fullName,
  company,
  branch,
  tel,
  location,
}) => () => {
  // eslint-disable-next-line no-new
  new Promise(async (resolve, reject) => {
    try {
      console.log('Updating user', fullName, company)

      // store user info in firestore
      const reference = firestore
        .collection('users')
        .doc(uid)
        .update({
          fullName,
          company,
          branch,
          tel,
          location,
        })
        .then((ref) => {
          console.log('Update profile', ref)
        })
    } catch (err) {
      reject(err)
    }
  })
}

const login = ({ email, password }) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const { user } = await auth.signInWithEmailAndPassword(email, password)
      if (!user) reject(new Error('Failed to login. please try it again later'))
      if (!user.emailVerified) await user.sendEmailVerification()
      dispatch(authenticate())
      resolve(user)
    } catch (err) {
      reject(err)
    }
  })

const logout = () => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await auth.signOut()
      dispatch(
        slice.actions.setMe({
          checked: true,
          loggedIn: false,
          me: {},
        }),
      )
      resolve()
    } catch (err) {
      reject(err)
    }
  })

const addCampaign = (
  { uid, name, regions, startDate, endDate, perDayBudget, totalBudget },
  downloadURL,
) => () =>
  new Promise(async (resolve, reject) => {
    try {
      console.log(uid, name, regions, startDate, endDate)

      // store user info in firestore
      const reference = firestore.collection('campaigns')
      await reference
        .add({
          name,
          regions,
          startDate,
          endDate,
          perDayBudget,
          totalBudget,
          downloadURL,
          userId: uid,
        })
        .then((ref) => {
          console.log(ref)
          const docId = ref.id
          reference.doc(docId).update({ campaignId: docId })
        })
    } catch (err) {
      reject(err)
    }
  })

const updateCampaign = ({
  campaignId,
  uid,
  name,
  regions,
  startDate,
  endDate,
  perDayBudget,
  totalBudget,
}) => () =>
  new Promise(async (resolve, reject) => {
    try {
      console.log(uid, name, regions, startDate, endDate)

      // store user info in firestore
      const reference = firestore
        .collection('campaigns')
        .doc(campaignId)
        .update({
          name,
          regions,
          startDate,
          endDate,
          perDayBudget,
          totalBudget,
        })
        .then((ref) => {
          console.log(ref)
        })
    } catch (err) {
      reject(err)
    }
  })

const deleteCampaign = ({ campaignId }) => () =>
  new Promise(async (resolve, reject) => {
    try {
      // store user info in firestore
      const reference = firestore
        .collection('campaigns')
        .doc(campaignId)
        .delete()
        .then((ref) => {
          console.log(ref)
        })
    } catch (err) {
      reject(err)
    }
  })

export const getCampaigns = ({ uid }) => async (dispatch) => {
  console.log(`searching campaigns by ${uid}`)
  const campaignData = await firestore
    .collection('campaigns')
    .where('userId', '==', uid)
    .get()

  // login
  return dispatch(
    slice.actions.setCampaigns({
      campaigns: campaignData,
    }),
  )
}

export const setSelectedVehicle = ({
  vehicleId,
  vehicleNumber,
  regions,
  balance,
  commissionedBy,
  preferredTimes,
}) => async (dispatch) => {
  console.log('Vehicle regions', regions, commissionedBy)

  return dispatch(
    slice.actions.setSelectedVehicle({
      selectedVehicle: {
        vehicleId,
        vehicleNumber,
        totalBalance: 0,
        totalHours: 0,
        balance,
        regions,
        commissionedBy,
        preferredTimes,
      },
    }),
  )
}

export const setSelectedCampaign = ({
  campaignId,
  name,
  regions,
  startDate,
  endDate,
  perDayBudget,
  totalBudget,
  impressions,
}) => async (dispatch) =>
  dispatch(
    slice.actions.setSelectedCampaign({
      selectedCampaign: {
        campaignId,
        name,
        regions,
        startDate,
        endDate,
        perDayBudget,
        totalBudget,
        impressions,
      },
    }),
  )

export const setDraftCampaign = (input) => async (dispatch) =>
  dispatch(
    slice.actions.setDraftCampaign({
      draftCampaign: input,
    }),
  )

export const setWithdrawalId = (id) => async (dispatch) => {
  console.log('setWithdrawalId ', id)
  dispatch(slice.actions.setWithdrawalId(id))
}

const addVehicle = ({
  uid,
  vehicleNumber,
  regions,
  commissionedBy,
  preferredTimes,
}) => () =>
  new Promise(async (resolve, reject) => {
    try {
      console.log(uid, vehicleNumber, regions)

      // store user info in firestore
      const reference = firestore.collection('vehicles')
      await reference
        .add({
          vehicleNumber,
          regions,
          commissionedBy,
          preferredTimes,
          userId: uid,
        })
        .then((ref) => {
          console.log(ref)
          const docId = ref.id
          reference.doc(docId).update({ vehicleId: docId })
        })
    } catch (err) {
      reject(err)
    }
  })

const updateVehicle = ({
  vehicleId,
  vehicleNumber,
  regions,
  commissionedBy,
  preferredTimes,
}) => () =>
  new Promise(async (resolve, reject) => {
    try {
      console.log(vehicleId, vehicleNumber, regions)
      // store user info in firestore
      firestore
        .collection('vehicles')
        .doc(vehicleId)
        .update({
          vehicleNumber,
          regions,
          commissionedBy,
          preferredTimes,
        })
        .then((ref) => {
          console.log(ref)
        })
    } catch (err) {
      reject(err)
    }
  })

const saveWithdrawal = ({
  vehicleId,
  userId,
  totalBalance,
  amount,
  timestamp,
}) => async (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const reference = firestore.collection('withdrawals')
      await reference
        .add({
          vehicleId,
          userId,
          totalBalance,
          amount,
          timestamp,
        })
        .then((ref) => {
          const docId = ref.id
          reference.doc(docId).update({ vehicleId: docId })

          dispatch(slice.actions.setWithdrawalId(docId))

          localStorage.setItem('saved_withdrawal', docId)
        })
    } catch (err) {
      reject(err)
    }
  })


const saveSlot = ({
                    timeSlot,
                    timeSlotName,
                    region,
                    viewsPerCharge,
                    advertisersRate,
                    driversRate,
                    }) => () =>
  new Promise(async (resolve, reject) => {
    try {
      // store user info in firestore
      const reference = firestore.collection('slots')
      await reference
        .add({
          // eslint-disable-next-line radix
          timeSlot: parseInt(timeSlot),
          timeSlotName,
          region,
          viewsPerCharge,
          advertisersRate: parseFloat(advertisersRate),
          driversRate: parseFloat(driversRate),
        })
        .then((ref) => {
          console.log(`Saving slot ${ ref}`);
          const docId = ref.id
          reference.doc(docId).update({ slotId: docId })
        })
    } catch (err) {
      console.log('Rejcted saving slot');
      reject(err)
    }
  })

const updateSlot = ({
    slotId,
                    viewsPerCharge,
                    advertisersRate,
                    driversRate,
                  }) => () =>
  new Promise(async (resolve, reject) => {
    try {
      // store user info in firestore
      firestore
        .collection('slots')
        .doc(slotId)
        .update({
          viewsPerCharge,
          advertisersRate,
          driversRate,
        })
        .then((ref) => {
          console.log(ref)
        })
    } catch (err) {
      reject(err)
    }
  })

const updateSettings = ({
                      viewsPerCharge,
                      advertisersRate,
                      driversRate,
                    }) => () =>
  new Promise(async (resolve, reject) => {
    try {
      // store user info in firestore
      firestore
        .collection('settings')
        .doc('RkJRYZcqdnUyW9ieD8dC')
        .update({
          viewsPerCharge,
          advertisersRate,
          driversRate,
        })
        .then((ref) => {
          console.log(ref)
        })
    } catch (err) {
      reject(err)
    }
  })

const resetPassword = (email) => () => auth.sendPasswordResetEmail(email)

// ------------------------------------
// Exports
// ------------------------------------

export const actions = {
  ...slice.actions,
  authenticate,
  signup,
  login,
  logout,
  resetPassword,
  addCampaign,
  updateCampaign,
  deleteCampaign,
  updateProfile,
  getCampaigns,
  getCompanies,
  loadRegions,
  addVehicle,
  updateVehicle,
  setSelectedVehicle,
  setSelectedCampaign,
  setDraftCampaign,
  saveWithdrawal,
  setWithdrawalId,
  saveSlot,
  updateSlot,
  updateSettings,
}

export default slice.reducer
