import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './home.module.scss'
import Header from '../../subviews/Header/Header'
import MainSlider from './main_slider'
import TargetVideo from './target_video'
import Footer from '../footer/footer'

import { path } from '../../utils/const'

const Home = () => (
  <div className={styles.root}>
    <Header />
    <MainSlider />
    <TargetVideo />

    <div className="container">
      <div className={styles.about_row}>
        <div className={styles.about_row__col}>
          <div className={styles.about_row__col__title}>About Us</div>
          <p>
            Find out how we started, what drives us, and how we’re igniting
            opportunity.
          </p>
          <a href="#">Learn more</a>
        </div>
        <div className={styles.about_row__col}>
          <div className={styles.about_row__col__title}>News</div>
          <p>
            See announcements about our latest releases, initiatives, and
            partnerships.
          </p>
          <a href="#">Learn more</a>
        </div>
        <div className={styles.about_row__col}>
          <div className={styles.about_row__col__title}>Global</div>
          <p>
            Read about our commitment to making a positive impact in the cities
            we serve.
          </p>
          <a href="#">Learn more</a>
        </div>
      </div>
    </div>

    <div className={styles.signup_row}>
      <div className="container">
        <div className={styles.signup_row__inner}>
          <a href={path.signup_driver}>
            Signup to Earn <FontAwesomeIcon icon="fa-solid fa-right-long" />
          </a>
          <a href={path.signup_advertiser}>
            Signup to Sell <FontAwesomeIcon icon="fa-solid fa-right-long" />
          </a>
        </div>
      </div>
    </div>
    <Footer />
  </div>
)

Home.propTypes = {}
Home.defaultProps = {}

export default Home
