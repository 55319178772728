import YouTube from 'react-youtube'
import styles from './target_video.module.scss'

const TargetVideo = () => {
  const opts = {
    height: 600,
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      controls: 0,
      autoplay: 1,
    },
  }

  return (
    <div className={styles.video_row}>
      <div className="container">
        <h2 className={styles.title}>Target advertising that works</h2>
        <YouTube
          videoId="z3nzW_rPP2k"
          id="video" // defaults -> null
          className={styles.target_video} // defaults -> null
          containerClassName={styles.target_video_container}
          opts={opts}
        />
      </div>
    </div>
  )
}

TargetVideo.propTypes = {}
TargetVideo.defaultProps = {}

export default TargetVideo
