import React, { useCallback, useState } from 'react'
import { addDays, format } from 'date-fns'
import { useDropzone } from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid'
import firebase from 'firebase'
import Alert from 'react-popup-alert'
import Select, { components } from 'react-select'
import CurrencyInput from 'react-currency-input-field'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { useCollection, useDocument } from 'react-firebase-hooks/firestore'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import 'react-popup-alert/dist/index.css' // theme css fil

import stylesDashboard from '../dashboard/dashboard.module.scss'
import { actions } from '../../slices/app.slice'
import { firestore } from '../../utils/firebase'
import Button from '../../components/Button'




const AdminAdvertisers = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { me } = useSelector((state) => state.app)
  const [currentIndex, setCurrentIndex] = useState()

  const [advertisers, loading, error] = useCollection(
    firestore.collection('users').where('usertype', '==', 'advertiser'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  )

  const toggleCampaign = (_campaignId) => {
    console.log(`hello ${_campaignId}`)
    setCurrentIndex(_campaignId)
  }

  const campaignDate = (startDate, endDate) => {
    const _start = format(startDate.toDate(), 'd MMMM Y')
    const _end = format(endDate.toDate(), 'd MMMM Y')
    return (
      <div>
        {_start} - {_end}
      </div>
    )
  }
  const regions = (_regions) => (
    <div className={stylesDashboard.regions}>
      {_regions.map((_reg, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={stylesDashboard.regions_name} key={index}>
          {_reg.label}
        </div>
      ))}
    </div>
  )

  const modifyCampaign = async (campaign) => {
    try {
      console.log(campaign)
      dispatch(actions.setSelectedCampaign(campaign))
      history.push(`/campaigns/${campaign.campaignId}`)
    } catch (err) {
      console.error('Error', err)
    }
  }

  const advertiserItem = (doc) => {
    const wData = doc.data()
    console.log(wData)

    return (
      <React.Fragment key={doc.id}>
        <div className={stylesDashboard.adv_item} data-id={doc.id}>
          <div className={stylesDashboard.adv_item_name}>{wData.fullName}</div>
          <div className={stylesDashboard.adv_item_email}>{wData.email}</div>
          <div className={stylesDashboard.adv_item_date}>{format(wData.createdOn.toDate(), 'd MMMM Y')}</div>
          <div className={stylesDashboard.adv_item_company}>{wData.company}</div>
          <div className={stylesDashboard.data_item_action}>
            {/*{wData.status ? (
              <div
                className={`${stylesDashboard.data_item_status} ${stylesDashboard.data_item_success}`}
              >
                Completed
              </div>
            ) : (
              <div className={`${stylesDashboard.data_item_status} `}>
                Pending
              </div>
            )}*/}
            <button
              type="button"
              className={`${stylesDashboard.data_item_status} ${stylesDashboard.data_item_edit}`}
            >
              Edit
            </button>
            <button
              type="button"
              className={`${stylesDashboard.data_item_status} ${stylesDashboard.data_item_cancel}`}
            >
              Delete
            </button>
          </div>
        </div>
      </React.Fragment>
    )
  }

  return (
    <div>
      <div className={stylesDashboard.title_back}>
        <a href="/dashboard/" className={stylesDashboard.back_to_page}>
          &nbsp;
        </a>
        Advertisers
      </div>

      <div className={stylesDashboard.campaign_list}>
        <div
          className={`${stylesDashboard.data_item} ${stylesDashboard.data_item_th}`}
        >
          <div className={stylesDashboard.adv_item_name}>Name</div>
          <div className={stylesDashboard.adv_item_email}>Email</div>
          <div className={stylesDashboard.adv_item_date}>Date</div>
          <div className={stylesDashboard.adv_item_company}>Company</div>
          <div className={stylesDashboard.data_item_action}></div>
        </div>

        {advertisers && (
          <div>{advertisers.docs.map((doc) => advertiserItem(doc))}</div>
        )}
      </div>
    </div>
  )
}
AdminAdvertisers.propTypes = {}
AdminAdvertisers.defaultProps = {}

export default AdminAdvertisers
