import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './signup_driver.module.scss'
import Header from '../../../subviews/Header/Header'
import Signup from './Signup'
import Footer from '../../footer/footer'

const AdvertiserSignup = () => (
  <div>
    <Header />
    <div className="container">
      <Signup userType="advertiser" />
    </div>
    <Footer />
  </div>
)

AdvertiserSignup.propTypes = {}
AdvertiserSignup.defaultProps = {}

export default AdvertiserSignup
