import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './footer.module.scss'

const Footer = () => (
  <div className={styles.footer_row}>
    <div className="container">
      <div className={styles.footer}>
        <div className={styles.footer__top}>
          <div className={styles.footer__logo}>MOVO</div>
          <a href="#">Visit help center</a>
        </div>
        <div className={styles.footer__middle}>
          <div className={styles.footer__middle_col1}>
            <p>Company</p>
            <a href="#">About us</a>
            <a href="#">Our offerings</a>
            <a href="#">Newsroom</a>
            <a href="#">Investors</a>
            <a href="#">Blog</a>
            <a href="#">Careers</a>
            <a href="#">Ai</a>
            <a href="#">Gift cards</a>
          </div>
          <div className={styles.footer__middle_col2}>
            <p>Global citizenship</p>
            <a href="#">Safety</a>
            <a href="#">Diversity & Ilusion</a>
          </div>
          <div className={styles.footer__middle_col3}>
            <p>Travel</p>
            <a href="#">Airports</a>
            <a href="#">Cities</a>
          </div>
        </div>

        <div className={styles.footer__bottom}></div>
      </div>
    </div>
  </div>
)

Footer.propTypes = {}
Footer.defaultProps = {}

export default Footer
