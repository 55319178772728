import firebase, { firestore } from '../utils/firebase'

const db = firestore.collection("slots");

const getAll = () => db;

const create = (data) => db.add(data);

const update = (id, value) => db.doc(id).update(value);

const remove = (id) => db.doc(id).delete();

const SlotService = {
  getAll,
  create,
  update,
  remove
};

export default SlotService;
