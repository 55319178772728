import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addDays } from 'date-fns'
import Select, { components } from 'react-select'
import Alert from 'react-popup-alert'
import { useCollection } from 'react-firebase-hooks/firestore'
import styles from './addvehicle.module.scss'
import stylesDashboard from '../dashboard/dashboard.module.scss'
import userImage from '../../assets/images/user.png'
import Input from '../../components/Input'
import { actions } from '../../slices/app.slice'

const EditVehicle = (props) => {
  const dispatch = useDispatch()
  const { onClose } = props

  const {
    me,
    companiesList,
    timeOptions,

    regionList,
    selectedVehicle,
  } = useSelector((state) => state.app)

  const [alert, setAlert] = useState({
    type: 'error',
    text: 'Vehicle added successfully.',
    show: false,
  })

  const [input, setInput] = useState({
    uid: me.id,
    vehicleId: selectedVehicle.vehicleId,
    vehicleNumber: selectedVehicle.vehicleNumber,
    regions: selectedVehicle.regions,
    commissionedBy: selectedVehicle.commissionedBy,
    preferredTimes: selectedVehicle.preferredTimes,
    key: 'selection',
    progress: 0,
    isLoading: false,
  })

  useEffect(() => {
    console.log('Region list', regionList)
  }, [])

  const handleOnChange = ({ target: { name, value } }) => {
    setInput((prev) => ({ ...prev, [name]: value }))
  }
  const handleRegionChange = (options) => {
    setInput((prev) => ({ ...prev, regions: options }))
  }

  const handleCommissionedChange = (options) => {
    setInput((prev) => ({ ...prev, commissionedBy: options }))
  }

  const handleTimeChange = (options) => {
    setInput((prev) => ({ ...prev, preferredTimes: options }))
  }

  const regionPlaceholder = (_props) => <components.Placeholder {..._props} />

  const handleVehicleSubmit = async () => {
    try {
      console.log(input)
      dispatch(actions.updateVehicle(input))
      setInput((prev) => ({ ...prev, isLoading: false }))
      dispatch(actions.setSelectedVehicle(input))

      props.onClose()
      setAlert((prev) => ({ ...prev, show: true }))
    } catch (err) {
      console.error('Error', err)
      setInput((prev) => ({ ...prev, isLoading: false }))
    }
  }

  const closeAddVehicle = () => {
    props.onClose()
  }
  const onCloseAlert = () => {
    setAlert({
      type: '',
      text: '',
      show: false,
    })
  }

  return (
    <div className={styles.add_vehicle_modal}>
      <div className={styles.add_vehicle_container}>
        <div className={stylesDashboard.modal_header}>Edit Vehicle</div>
        <div className={stylesDashboard.form_field}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={styles.field_label}>Vehicle number</label>
          <div className="field_v_number">
            <Input
              className="field-b-b"
              placeholder="Vehicle number"
              name="vehicleNumber"
              value={input.vehicleNumber}
              onChange={handleOnChange}
            />
          </div>
        </div>

        <div className={stylesDashboard.form_field}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={styles.field_label}>Commissioned areas</label>
          <Select
            value={input.regions}
            onChange={handleRegionChange}
            className={styles.field_region}
            isMulti
            closeMenuOnSelect={false}
            components={{ regionPlaceholder }}
            placeholder="Region"
            options={regionList}
          />
        </div>

        <div className={stylesDashboard.form_field}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={styles.field_label}>Commissioned By</label>
          <Select
            value={input.commissionedBy}
            onChange={handleCommissionedChange}
            className={styles.field_region}
            isMulti
            closeMenuOnSelect={false}
            components={{ regionPlaceholder }}
            placeholder="Commissioned by"
            options={companiesList}
          />
        </div>

        <div className={stylesDashboard.form_field}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={styles.field_label}>Preferred times</label>
          <Select
            value={input.preferredTimes}
            onChange={handleTimeChange}
            className={styles.field_region}
            isMulti
            closeMenuOnSelect={false}
            components={{ regionPlaceholder }}
            placeholder="Preferred times"
            options={timeOptions}
          />
        </div>

        <hr />

        <div className={stylesDashboard.modal_controls}>
          <button
            type="button"
            className={stylesDashboard.btn}
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className={stylesDashboard.btn}
            onClick={handleVehicleSubmit}
          >
            Update
          </button>
        </div>
      </div>

      <Alert
        header="Updated Vehicle"
        btnText="Close"
        text={alert.text}
        type={alert.type}
        show={alert.show}
        onClosePress={onCloseAlert}
        pressCloseOnOutsideClick="true"
        showBorderBottom="true"
        alertStyles={{}}
        headerStyles={{}}
        textStyles={{}}
        buttonStyles={{}}
      />
    </div>
  )
}

EditVehicle.propTypes = {}
EditVehicle.defaultProps = {}

export default EditVehicle
