import styles from './main_slider.module.scss'

const MainSlider = () => (
  <div className={styles.main_slider_row}>
    <div className="container">
      <div className={styles.main_slider}>
        <div className={styles.slide}>
          <div className={styles.slide_inner}>Display & Earn</div>
        </div>
        <div className={styles.slide}>
          <div className={styles.slide_inner}>Advertise & Sell</div>
        </div>
      </div>
    </div>
  </div>
)

MainSlider.propTypes = {}
MainSlider.defaultProps = {}

export default MainSlider
