import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faLongArrowAltRight,
} from '@fortawesome/free-solid-svg-icons'
import Button from 'components/Button'
import { actions } from 'slices/app.slice'
import images from 'assets/images'

const DriverService = (props) => {
  const dispatch = useDispatch()
  const { me } = useSelector((state) => state.app)
  const userType = me?.usertype

  const [showBack, setShowBack] = useState(false)



  return (
    ''
  )
}

DriverService.propTypes = {}
DriverService.defaultProps = {}

export default DriverService
