import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './header.module.scss'
import images from '../../assets/images'
import iconLogout from '../../assets/images/icon_logout.png'
import { actions } from '../../slices/app.slice'
import { path } from '../../utils/const'

const Header = () => {
  const { me, selectedVehicle } = useSelector((state) => state.app)
  const dispatch = useDispatch()

  console.log(me)

  return (
    <div className={styles.header}>
      <div className={styles.container}>
        <a href="/" className={styles.logo}>
          MOVO
        </a>
        {/*<div className={styles.menu_button}></div>*/}
        {/* eslint-disable-next-line react/button-has-type */}



        {
          me.id ? (<button type="button"
            className={styles.menu_logout}
            onClick={() => dispatch(actions.logout())}
          >
            <img src={iconLogout} className={styles.logo} alt="Logout" />
          </button>):<a className={styles.login} href={path.login}>Login</a>
        }

      </div>
    </div>
  )
}

export default Header
