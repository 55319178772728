import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './signup_driver.module.scss'
import Header from '../../../subviews/Header/Header'
import Signup from './Signup'
import Footer from '../../footer/footer'

const DriverSignup = () => (
  <div>
    <Header />
    <div className="container">
      <Signup userType="driver" />
    </div>
    <Footer />
  </div>
)

DriverSignup.propTypes = {}
DriverSignup.defaultProps = {}

export default DriverSignup
