import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './loginpage.module.scss'
import Header from '../../../subviews/Header/Header'
import Footer from '../../footer/footer'
import Login from './Login'

const LoginPage = () => (
  <div>
    <Header />
    <div className="container">
      <Login />
    </div>
    <Footer />
  </div>
)

LoginPage.propTypes = {}
LoginPage.defaultProps = {}

export default LoginPage
