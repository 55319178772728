import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import app from 'slices/app.slice'

const store = configureStore({
  reducer: {
    app,
  },

  middleware:
    process.env.NODE_ENV === 'production'
      ? [
          ...getDefaultMiddleware({
            serializableCheck: false,
          }),
        ]
      : [
          ...getDefaultMiddleware({
            serializableCheck: false,
          }),
          logger,
        ],
})

export default store
