import React, { useCallback, useEffect, useState } from 'react'
import { addDays, format } from 'date-fns'
import { useDropzone } from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid'
import firebase from 'firebase'
import Alert from 'react-popup-alert'
import Select, { components } from 'react-select'
import CurrencyInput from 'react-currency-input-field'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { useCollection, useDocument } from 'react-firebase-hooks/firestore'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import 'react-popup-alert/dist/index.css' // theme css fil

import stylesDashboard from '../dashboard/dashboard.module.scss'
import { actions } from '../../slices/app.slice'
import { firestore } from '../../utils/firebase'
import Button from '../../components/Button'
import EditVehicle from '../driver/EditVehicle'
import AddSlot from './AddSlot'
import SlotService from '../../services/SlotsService'
import EditSlot from './EditSlot'
import styles from '../driver/addvehicle.module.scss'
import Input from '../../components/Input'
import SettingsService from '../../services/SettingsService'




const AdminSettings = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { me, timeOptions } = useSelector((state) => state.app)



  const [settings, loading, error] = useCollection(
    firestore.collection('settings'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  )

  const [input, setInput] = useState({
    viewsPerCharge: 1,
    driversRate: 0,
    advertisersRate: 0,
  })

  const onDataChange = (items) => {
    const _slots = [];
    console.log(`data changed`, items);
    const _selectedRegions = [];

    items.docs.forEach((item) => {
      const {id} = item;
      const data = item.data();

      setInput({
        viewsPerCharge: data.viewsPerCharge,
        driversRate: data.driversRate,
        advertisersRate: data.advertisersRate,
      });
    });
  };

  useEffect(() => {
    const unsubscribe = SettingsService.getAll().onSnapshot(onDataChange);

    return () => unsubscribe();
  }, []);

  const handleOnChange = ({ target: { name, value } }) => {
    setInput((prev) => ({ ...prev, [name]: value }))
  }

  const saveSettings = async () => {
    try {
      console.log(input)
      dispatch(actions.updateSettings(input))
      setInput((prev) => ({ ...prev, isLoading: false }))
    } catch (err) {
      console.error('Error', err)
      setInput((prev) => ({ ...prev, isLoading: false }))
    }
  }

  return (
    <div>
      <div className={stylesDashboard.title_back}>
        <a href="/dashboard/" className={stylesDashboard.back_to_page}>
          &nbsp;
        </a>
        Settings

        <button type="button" onClick={() => saveSettings()}>
          Save
        </button>
      </div>

      <hr/>

      <div>
        <div className={stylesDashboard.form_field}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={styles.field_label}>Views / Charge</label>
          <div className="field_value">
            <Input
              className="field-b-b"
              placeholder="Time slot"
              name="viewsPerCharge"
              value={`${input.viewsPerCharge}`}
              onChange={handleOnChange}
            />
          </div>
        </div>

        <div className={stylesDashboard.form_field}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={styles.field_label}>Drivers Rate</label>
          <div className="field_value">
            <Input
              className="field-b-b"
              placeholder="0"
              name="advertisersRate"
              value={`${input.advertisersRate}`}
              onChange={handleOnChange}
            />
          </div>
        </div>

        <div className={stylesDashboard.form_field}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={styles.field_label}>Advertisers Rate</label>
          <div className="field_value">
            <Input
              className="field-b-b"
              placeholder="0"
              name="driversRate"
              type="number"
              value={`${input.driversRate}`}
              onChange={handleOnChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
AdminSettings.propTypes = {}
AdminSettings.defaultProps = {}

export default AdminSettings
